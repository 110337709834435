import React from "react";
import { Route, Routes } from "react-router-dom";
import { Body } from "./components/Body";
import { Header } from "./components/Header";
import { Home } from "./components/Home";
import { Settings } from "./components/Settings";
import { Table } from "./components/Table";
import { initFirebase } from "./firebase";
import { useAuth } from "./hooks/useAuth";

initFirebase();

function App() {
  const { user } = useAuth();

  return (
    <div className="App">
      <Header user={user} />
      <Body>
        <Routes>
          <Route path="/" element={<Home user={user} />} />
          <Route path="/home" element={<Home user={user} />} />
          <Route path="/table" element={<Table user={user} />} />
          <Route path="/settings" element={<Settings user={user} />} />
        </Routes>
      </Body>
    </div>
  );
}

export default App;
