import { getAuth, User } from "firebase/auth";
import { useEffect, useState } from "react";
import { loadCommits } from "../features/commits.feature";
import { store } from "../features/store.feature";
import { loadUsers } from "../features/users.feature";

export const useAuth = () => {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    getAuth().onAuthStateChanged((user) => {
      if (user) {
        console.info("USER AUTHENTIFICATED");
        store.dispatch(loadCommits());
        store.dispatch(loadUsers());
        setUser(user);
      } else {
        console.info("USER NOT AUTHENTIFICATED");
        setUser(null);
      }
    });
  }, []);

  return { user };
};
