import { Button } from "@mui/material";
import { getAuth, GoogleAuthProvider, signInWithRedirect } from "firebase/auth";

export const Login = () => {
  const provider = new GoogleAuthProvider();
  const login = async () => {
    try {
      await signInWithRedirect(getAuth(), provider);
      console.info("LOGIN");
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Button color="inherit" onClick={login}>
      login
    </Button>
  );
};
