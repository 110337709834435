import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { getAuth } from "firebase/auth";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  query,
  setDoc,
} from "firebase/firestore";
import { Commit } from "../models/commit.model";
import { State } from "./store.feature";

const commitAdapter = createEntityAdapter<Commit>({
  selectId: (commit) => commit.id,
});

export const loadCommits = createAsyncThunk("commits/loadCommits", async () => {
  const user = getAuth().currentUser;
  const commits: Commit[] = [];
  const path = `users/${user?.uid}/commits`;
  const q = query(collection(getFirestore(), path));
  try {
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => commits.push(doc.data() as Commit));
  } catch (err) {
    console.log(err);
  }
  return commits;
});

export const getCommits = createAsyncThunk("commits/getCommits", async () => {
  const user = getAuth().currentUser;
  const commits: Commit[] = [];
  const path = `users/${user?.uid}/commits`;
  const q = query(collection(getFirestore(), path));
  try {
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => commits.push(doc.data() as Commit));
  } catch (err) {
    console.log(err);
  }
  return commits;
});

export const addCommit = createAsyncThunk(
  "commits/addCommit",
  (commit: Commit) => {
    const user = getAuth().currentUser;
    const path = `users/${user?.uid}/commits/${commit.id}`;
    try {
      setDoc(doc(getFirestore(), path), commit);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
    return commit;
  },
);

export const removeCommit = createAsyncThunk(
  "commits/removeCommit",
  (id: string) => {
    const user = getAuth().currentUser;
    const path = `users/${user?.uid}/commits/${id}`;
    try {
      deleteDoc(doc(getFirestore(), path));
    } catch (e) {
      console.error("Error deleting document: ", e);
    }
    return id;
  },
);

export const updateSync = createAsyncThunk("commits/updateSync", async () => {
  let unsynced;
  const user = getAuth().currentUser;
  const path = `users/${user?.uid}/commits`;
  const q = query(collection(getFirestore(), path));
  try {
    const snapshot = await getDocs(q);
    unsynced = snapshot.metadata.hasPendingWrites;
  } catch (err) {
    console.log(err);
  }
  return unsynced;
});

const initialState = { unsynced: false, ...commitAdapter.getInitialState() };

export const commitsSlice = createSlice({
  name: "commits",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addCommit.fulfilled, commitAdapter.addOne)
      .addCase(removeCommit.fulfilled, commitAdapter.removeOne)
      .addCase(loadCommits.fulfilled, (state, action) => {
        commitAdapter.setAll(state, action.payload);
      })
      .addCase(updateSync.fulfilled, (state, action) => {
        state.unsynced = action.payload ?? false;
      });
  },
});

const selectors = commitAdapter.getSelectors((state: State) => state.commits);

export const selectCommitIds = createSelector(selectors.selectAll, (commits) =>
  commits.map((commit) => commit.id),
);

export const { selectAll: selectCommits, selectById: selectCommitById } =
  commitAdapter.getSelectors((state: State) => state.commits);

export const isUnsynced = (state: State) => state.commits.unsynced;
