import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import {
  connectFirestoreEmulator,
  enableIndexedDbPersistence,
  getFirestore,
} from "firebase/firestore";

const FIREBASE_CONFIG = {
  apiKey: "AIzaSyDyx4VFYgDWSEAW4eMjl0myiWdruxSFfO8",
  authDomain: "test-vossen-digital.firebaseapp.com",
  projectId: "test-vossen-digital",
  storageBucket: "test-vossen-digital.appspot.com",
  messagingSenderId: "204001559689",
  appId: "1:204001559689:web:0506f1c2ccf90c24f97258",
  measurementId: "G-P99VDSKV2J",
};

export const initFirebase = () => {
  initializeApp(FIREBASE_CONFIG);
  console.info("FIREBASE APP");

  const auth = getAuth();
  console.info("FIREBASE AUTH");

  const db = getFirestore();
  console.info("FIRESTORE");

  if (process.env.NODE_ENV === "development") {
    connectAuthEmulator(auth, "http://localhost:9099");
    connectFirestoreEmulator(db, "localhost", 8080);
    console.info("FIREBASE EMULATOR");
  }

  if (process.env.NODE_ENV === "production") {
    enableIndexedDbPersistence(db).catch((err) => {
      if (err.code === "failed-precondition") {
        console.error("Multiple tabs not allowed in offline mode.");
      } else if (err.code === "unimplemented") {
        console.error("This browser does not support offline mode.");
      }
    });
  }
};
