import { Grid } from "@mui/material";
import { User } from "firebase/auth";
import { ListCommit } from "./ListCommit";
import { StoreCommit } from "./StoreCommit";

interface Props {
  user: User | null;
}

export const Home = ({ user }: Props) => {
  if (user) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <StoreCommit />
        </Grid>
        <Grid item xs={6}>
          <ListCommit />
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
};
