import { configureStore } from "@reduxjs/toolkit";
import { commitsSlice } from "./commits.feature";
import { userSlice } from "./users.feature";

export const store = configureStore({
  reducer: {
    commits: commitsSlice.reducer,
    users: userSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type State = ReturnType<typeof store.getState>;
