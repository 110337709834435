import { Grid } from "@mui/material";
import { User } from "firebase/auth";
import { TableCommit } from "./TableCommit";

interface Props {
  user: User | null;
}

export const Table = ({ user }: Props) => {
  if (user) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableCommit />
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
};
