import CommitIcon from "@mui/icons-material/Commit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { removeCommit, selectCommitById } from "../features/commits.feature";
import { useAppDispatch, useAppSelector } from "../hooks/useState";

interface Props {
  id: string;
}

export const Commit = ({ id }: Props) => {
  const dispatch = useAppDispatch();

  const commit = useAppSelector((state) => selectCommitById(state, id));

  const handleRemoveCommit = async (id: string) => {
    dispatch(removeCommit(id));
  };

  return (
    <ListItem
      secondaryAction={
        <IconButton
          onClick={() => handleRemoveCommit(commit?.id ?? "")}
          edge="end"
          aria-label="delete"
        >
          <DeleteIcon />
        </IconButton>
      }
    >
      <ListItemAvatar>
        <Avatar>
          <CommitIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={commit?.message} secondary={commit?.branch} />
    </ListItem>
  );
};
