import { Autocomplete, FormControl, Grid, TextField } from "@mui/material";
import { User } from "firebase/auth";
import { selectUsers } from "../features/users.feature";
import { useAppSelector } from "../hooks/useState";
interface Props {
  user: User | null;
}

export const Settings = ({ user }: Props) => {
  const users = useAppSelector(selectUsers);
  const names = users.map((user) => user.prename);

  if (user) {
    const settings = [
      { label: "Email", value: user.email, readOnly: true },
      { label: "Name", value: user.displayName, readOnly: true },
    ];

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            {settings.map((setting) => {
              return (
                <TextField
                  label={setting.label}
                  key={setting.label}
                  defaultValue={setting.value}
                  variant="outlined"
                  style={{ margin: "8px" }}
                  InputProps={{
                    readOnly: setting.readOnly,
                  }}
                />
              );
            })}
            <Autocomplete
              disablePortal
              options={names}
              style={{ margin: "8px" }}
              renderInput={(params) => <TextField {...params} label="Friend" />}
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
};
