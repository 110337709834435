import SyncProblemIcon from "@mui/icons-material/SyncProblem";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { getAuth, signOut, User } from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isUnsynced, updateSync } from "../features/commits.feature";
import { useAppDispatch, useAppSelector } from "../hooks/useState";
import { routes } from "../models/routes.model";
interface Props {
  user: User | null;
}

export const Logout = ({ user }: Props) => {
  const navigate = useNavigate();
  const [offline, setOffline] = useState(false);
  const dispatch = useAppDispatch();
  const unsynced = useAppSelector(isUnsynced);

  useEffect(() => {
    const interval = setInterval(() => dispatch(updateSync()), 10_000);
    return () => clearInterval(interval);
  });

  window.addEventListener("online", () => setOffline(false));
  window.addEventListener("offline", () => setOffline(true));

  const logout = async () => {
    try {
      await signOut(getAuth());
      navigate("/");
      console.info("LOGOUT");
    } catch (e) {
      console.error(e);
    }
  };

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <Box sx={{ flexGrow: 0 }}>
        {unsynced && (
          <Tooltip title="You have unsynced changes">
            <IconButton size="large" color="inherit">
              <SyncProblemIcon />
            </IconButton>
          </Tooltip>
        )}
        {offline && (
          <Tooltip title="You are offline">
            <IconButton size="large" color="inherit">
              <WifiOffIcon />
            </IconButton>
          </Tooltip>
        )}
        <IconButton onClick={handleOpenUserMenu}>
          <Avatar src={user?.photoURL ?? ""} />
        </IconButton>
        <Menu
          id="menu-appbar"
          sx={{ mt: "45px" }}
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {routes.map((route) => {
            return (
              <MenuItem key={route} onClick={() => navigate("/" + route)}>
                {route}
              </MenuItem>
            );
          })}
          <MenuItem onClick={logout}>logout</MenuItem>
        </Menu>
      </Box>
    </>
  );
};
