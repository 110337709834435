import { DataGrid, GridColDef } from "@mui/x-data-grid";
import * as React from "react";
import { selectCommits } from "../features/commits.feature";
import { useAppSelector } from "../hooks/useState";

const columns: GridColDef[] = [
  { field: "id", headerName: "id", flex: 1 },
  { field: "author", headerName: "author", flex: 1 },
  { field: "message", headerName: "message", flex: 1 },
  { field: "branch", headerName: "branch", flex: 1 },
];

export const TableCommit = () => {
  const commits = useAppSelector(selectCommits);

  return (
    <DataGrid
      autoHeight
      rows={commits}
      columns={columns}
      pageSize={10}
      rowsPerPageOptions={[10]}
      checkboxSelection
    />
  );
};
