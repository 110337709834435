import faker from "@faker-js/faker";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { addCommit } from "../features/commits.feature";
import { useAppDispatch } from "../hooks/useState";

export const StoreCommit = () => {
  const dispatch = useAppDispatch();

  const handleAddCommit = async () => {
    dispatch(
      addCommit({
        id: faker.datatype.uuid(),
        author: faker.name.firstName(),
        message: faker.git.commitMessage(),
        branch: faker.git.branch(),
      }),
    );
  };

  return (
    <List
      sx={{ width: "100%", maxWidth: 360 }}
      subheader={<ListSubheader>Actions</ListSubheader>}
    >
      <ListItem disablePadding>
        <ListItemButton onClick={handleAddCommit}>
          <ListItemIcon>
            <AddCircleIcon />
          </ListItemIcon>
          <ListItemText primary="Add commit" />
        </ListItemButton>
      </ListItem>
    </List>
  );
};
