import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { User } from "../models/user.model";
import { State } from "./store.feature";

const userAdapter = createEntityAdapter<User>({
  selectId: (user) => user.id,
});

export const loadUsers = createAsyncThunk("users/loadUsers", async () => {
  const users: User[] = [];
  const url = `https://api-vossen-digital-default-rtdb.europe-west1.firebasedatabase.app/users.json?orderBy="prename"&startAt=0`;
  await fetch(url)
    .then((response) => response.json())
    .then((data) => {
      Object.keys(data).forEach((id) => {
        users.push({ id: id, ...data[id] });
      });
      console.log("FETCH");
    });

  return users;
});

export const userSlice = createSlice({
  name: "users",
  initialState: userAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadUsers.fulfilled, (state, action) => {
      userAdapter.setAll(state, action.payload);
    });
  },
});

export const { selectAll: selectUsers } = userAdapter.getSelectors(
  (state: State) => state.users,
);
