import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import { User } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Login } from "./Login";
import { Logout } from "./Logout";
interface Props {
  user: User | null;
}

export const Header = ({ user }: Props) => {
  const navigate = useNavigate();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            onClick={() => navigate("/")}
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <EmojiPeopleIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Hello {user && user.displayName}
          </Typography>
          {user ? <Logout user={user} /> : <Login />}
        </Toolbar>
      </AppBar>
    </Box>
  );
};
