import { List, ListSubheader } from "@mui/material";
import { selectCommitIds } from "../features/commits.feature";
import { useAppSelector } from "../hooks/useState";
import { Commit } from "./Commit";

export const ListCommit = () => {
  const ids = useAppSelector(selectCommitIds);

  return (
    <List
      sx={{ width: "100%", maxWidth: 360 }}
      subheader={ids && <ListSubheader>Commits</ListSubheader>}
    >
      {ids.map((id: string) => {
        return <Commit key={id} id={id} />;
      })}
    </List>
  );
};
